import { EnumTransactionType } from '@blank/api'
import dayjs from 'dayjs'

export const TRANSACTION_PENDING_LIMIT_HOURS = 72

export const getIsTransactionPending = (
  date: string,
  transactionType: EnumTransactionType
) => {
  if (transactionType !== EnumTransactionType.DEBIT) {
    return false
  }

  const pendingLimitDate = dayjs(date).add(
    TRANSACTION_PENDING_LIMIT_HOURS,
    'hour'
  )

  return pendingLimitDate.isAfter(dayjs())
}
